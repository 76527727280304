
const Questions = [
    {
        Question: "Hva er viktig å ha opplæring i for å sikre riktig betaling i drosjen?",
        Answers:["Taksameter og betalingsterminal","GPS-navigasjon","Kjøretøyets tekniske spesifikasjoner","Lokal geografi"],
        CorrectAnswer: "Taksameter og betalingsterminal",
        Category: "Opplæring av ansatte"
    },
    {
        Question:"Hva menes med god kjøreadferd?",
        Answers: ["Sikker, hensynsfull og effektiv kjøring","Rask kjøring for å sikre flere turer","Alltid å følge GPS-anvisninger","Å kjøre i høyeste hastighet tillatt"],
        CorrectAnswer: "Sikker, hensynsfull og effektiv kjøring",
        Category: "Opplæring av ansatte"
    },
    {
        Question:"Hvilket dokument må en drosjesjåfør ha tilgjengelig i bilen hvis passasjeren spør?",
        Answers: ["Priskalkuleringsskjema","Kjøreseddel","Løyvenummer","Førerkort"],
        CorrectAnswer: "Priskalkuleringsskjema",
        Category: "Opplæring av ansatte"
    },
    {
        Question:"Hva er lovpålagt kontroll av kjøretøyet kjent som?",
        Answers: ["Periodisk kjøretøykontroll","Årlig service","Teknisk inspeksjon","Sikkerhetskontroll"],
        CorrectAnswer: "Periodisk kjøretøykontroll",
        Category: "Opplæring av ansatte"
    },
    {
        Question:"Hva er kravet for kjøreseddel i yrkestransportloven kap. 7a?",
        Answers: ["Den skal være gyldig og oppdatert","Den skal være på engelsk","Den skal inneholde bilens tekniske spesifikasjoner","Den skal fornyes hver måned"],
        CorrectAnswer: "Den skal være gyldig og oppdatert",
        Category: "Opplæring av ansatte"
    },
    {
        Question: "Hvordan kan en drosjesjåfør legge til rette for god service for eldre passasjerer?",
        Answers: ["Ved å hjelpe dem inn og ut av bilen, snakke tydelig og kjøre forsiktig", "Ved å spille høy musikk", "Ved å kjøre raskt for å spare deres tid", "Ved å unngå samtale for å ikke forstyrre dem"],
        CorrectAnswer: "Ved å hjelpe dem inn og ut av bilen, snakke tydelig og kjøre forsiktig",
        Category: "Opplæring av ansatte"
      },
    {
        Question: "Hvilken forskrift omhandler krav til dokumentasjon for systematisk HMS-arbeid i virksomheten?",
        Answers: ["Internkontrollforskriften", "Arbeidsmiljøloven", "Helse- og sikkerhetsloven", "HMS-dokumentasjonsloven"],
        CorrectAnswer: "Internkontrollforskriften",
        Category: "Internkontroll"
      },
      {
        Question: "Hva er hovedmålet med internkontroll i en drosjevirksomhet?",
        Answers: ["Å sikre at virksomheten opererer i samsvar med kravene i helse-, miljø- og sikkerhetslovgivningen", "Å overvåke ansatte", "Å redusere skatter og avgifter", "Å maksimere fortjenesten"],
        CorrectAnswer: "Å sikre at virksomheten opererer i samsvar med kravene i helse-, miljø- og sikkerhetslovgivningen",
        Category: "Internkontroll"
      },
      {
        Question: "Hvor mye pause har man rett på ifølge arbeids- og hvilebestemmelser for en 37,5 timers uke?",
        Answers: ["30 minutter", "15 minutter", "45 minutter", "60 minutter"],
        CorrectAnswer: "30 minutter",
        Category: "Arbeidsmiljøloven og ferieloven"
      },
      {
        Question: "Hva er pålagt å ha med i en arbeidskontrakt?",
        Answers: ["Arbeidstid og lønnsbestingelser", "Ferieplan og bonusstruktur", "Kjøretøyets spesifikasjoner", "Antall kunder betjent"],
        CorrectAnswer: "Arbeidstid og lønnsbestingelser",
        Category: "Arbeidsmiljøloven og ferieloven"
      },
      {
        Question: "Hva er oppsigelsesfristen i et arbeidsforhold dersom den ikke er oppgitt i arbeidskontrakten?",
        Answers: ["30 dager/en måned", "14 dager", "7 dager", "60 dager"],
        CorrectAnswer: "30 dager/en måned",
        Category: "Arbeidsmiljøloven og ferieloven"
      },
      {
        Question: "Hvem bestemmer tidspunkt for ferieavvikling til de ansatte?",
        Answers: ["Arbeidsgiver", "Den ansatte", "Fagforeningen", "Samferdselsdepartementet"],
        CorrectAnswer: "Arbeidsgiver",
        Category: "Arbeidsmiljøloven og ferieloven"
      },
      {
        Question: "Hva er kravet for enerett på løyve i en kommune?",
        Answers: ["Maksimalt 200 000 innbyggere og befolkningstetthet på 80 per km2", "Minst 500 000 innbyggere", "Ingen spesifikke krav", "Maksimalt 100 000 innbyggere uavhengig av befolkningstetthet"],
        CorrectAnswer: "Maksimalt 200 000 innbyggere og befolkningstetthet på 80 per km2",
        Category: "Arbeidsmiljøloven og ferieloven"
      },
      {
        Question: "Hvilken lov regulerer arbeidstakers rettigheter og plikter i forhold til ferie?",
        Answers: ["Ferieloven", "Arbeidsmiljøloven", "Lov om offentlige helligdager", "Lov om arbeidstid og pauser"],
        CorrectAnswer: "Ferieloven",
        Category: "Arbeidsmiljøloven og ferieloven"
      },
      {
        Question: "Hva er den maksimale daglige arbeidstiden ifølge Arbeidsmiljøloven?",
        Answers: ["9 timer i løpet av 24 timer og 40 timer i uken", "10 timer uavhengig av type arbeid og 37,5 timer i uken.", "8 timer for alle typer arbeid", "7,5 timer for kontorarbeid og 8,5 timer for annet arbeid"],
        CorrectAnswer: "9 timer i løpet av 24 timer og 40 timer i uken",
        Category: "Arbeidsmiljøloven og ferieloven"
      },
      {
        Question: "Hva er den vanligste årsaken til arbeidsrelaterte sykdommer og skader i transportsektoren?",
        Answers: ["Muskel- og skjelettplager", "Stress og psykiske lidelser", "Hørselsskader", "Synsproblemer"],
        CorrectAnswer: "Muskel- og skjelettplager",
        Category: "Arbeidsmiljøloven og ferieloven"
      },
      {
        Question: "Hvilken bestemmelse i Arbeidsmiljøloven gir arbeidstaker rett til å varsle om kritikkverdige forhold på arbeidsplassen?",
        Answers: ["§ 2-4", "§ 3-1", "§ 4-2", "§ 5-6"],
        CorrectAnswer: "§ 2-4",
        Category: "Arbeidsmiljøloven og ferieloven"
      },
      {
        Question: "Hvilken av følgende er IKKE en av arbeidsgivers plikter ifølge Arbeidsmiljøloven?",
        Answers: ["Å sørge for at arbeidstaker har tilgang til gratis kaffe og te", "Å sørge for at arbeidsmiljøet er fullt forsvarlig", "Å sørge for at arbeidstaker får nødvendig opplæring", "Å sørge for at arbeidstaker får nødvendig verneutstyr"],
        CorrectAnswer: "Å sørge for at arbeidstaker har tilgang til gratis kaffe og te",
        Category: "Arbeidsmiljøloven og ferieloven"
      },
      {
        Question: "Hva er hovedformålet med Arbeidsmiljøloven?",
        Answers: ["Å sikre et arbeidsmiljø som gir grunnlag for en helsefremmende og meningsfylt arbeidssituasjon", "Å maksimere produktiviteten i virksomheten", "Å redusere antall arbeidstimer", "Å sikre høyest mulig lønn for arbeidstakere"],
        CorrectAnswer: "Å sikre et arbeidsmiljø som gir grunnlag for en helsefremmende og meningsfylt arbeidssituasjon",
        Category: "Arbeidsmiljøloven og ferieloven"
      },
      {
        Question: "Hvem har ansvaret for å følge opp sykmeldte arbeidstakere?",
        Answers: ["Arbeidsgiver", "Arbeidstaker selv", "NAV", "Fagforeningen"],
        CorrectAnswer: "Arbeidsgiver",
        Category: "Arbeidsmiljøloven og ferieloven"
      },{
        Question: "Hva er hovedformålet med ferieloven?",
        Answers: [
          "Å sikre at arbeidstakere får årlig ferie og feriepenger",
          "Å sikre at arbeidstakere får fri på helligdager",
          "Å regulere antall arbeidstimer i en uke",
          "Å sikre at arbeidstakere får overtidsbetaling"
        ],
        CorrectAnswer: "Å sikre at arbeidstakere får årlig ferie og feriepenger",
        Category: "Arbeidsmiljøloven og ferieloven"
      },
      {
        Question: "Hvor mange virkedager ferie har en arbeidstaker rett til ifølge ferieloven?",
        Answers: ["25 virkedager", "20 virkedager", "30 virkedager", "28 virkedager"],
        CorrectAnswer: "25 virkedager",
        Category: "Arbeidsmiljøloven og ferieloven"
      },
      {
        Question: "Hva er riktig om feriepenger?",
        Answers: [
          "Feriepenger skal utgjøre minimum 10,02 % av brutto lønn og 12,5 % etter fylte 60 år.",
          "Feriepenger skal utgjøre minimum 12 % av brutto lønn.",
          "Feriepenger utbetales samme år som de blir avsatt.",
          "Arbeidstaker har selv ansvar for å sette av feriepenger."
        ],
        CorrectAnswer: "Feriepenger skal utgjøre minimum 10,02 % av brutto lønn og 12,5 % etter fylte 60 år.",
        Category: "Arbeidsmiljøloven og ferieloven"
      },
      {
        Question: "Hvordan kan arbeidsgiver levere oppsigelse til arbeidstaker?",
        Answers: [
          "Arbeidsgiver må levere oppsigelse personlig til arbeidstaker eller sende oppsigelse i rekommandert brev.",
          "Arbeidsgiver må levere oppsigelse muntlig til arbeidstaker.",
          "Arbeidstaker må gi beskjed til sentralen om at han har sagt opp en ansatt.",
          "Arbeidsgiver må legge oppsigelse inn i Altinn."
        ],
        CorrectAnswer: "Arbeidsgiver må levere oppsigelse personlig til arbeidstaker eller sende oppsigelse i rekommandert brev.",
        Category: "Arbeidsmiljøloven og ferieloven"
      },
      {
        Question: "Hvor ofte tar arbeidstaker HMS-kurs?",
        Answers: [
          "Kontinuerlig",
          "1 gang per måned",
          "1 gang hver 6. mnd",
          "Aldri"
        ],
        CorrectAnswer: "Kontinuerlig",
        Category: "Arbeidsmiljøloven og ferieloven"
      },
      {
        Question: "Hva er hovedformålet med forskriften om takstberegning og maksimalpriser for drosje?",
        Answers: [
          "Å sikre transparente og rettferdige priser for forbrukerne",
          "Å maksimere inntektene for drosjeselskapene",
          "Å redusere antall drosjer på veien",
          "Å fremme konkurranse mellom drosjeselskapene"
        ],
        CorrectAnswer: "Å sikre transparente og rettferdige priser for forbrukerne",
        Category: "Regler om prisopplysning og makspriser"
      },
      {
        Question: "Hvilken forskrift regulerer hvordan prisene i drosjemarkedet bestemmes?",
        Answers: [
          "Forskrift om takstberegning og maksimalpriser for løyvepliktig drosjetransport med motorvogn",
          "Lov om merverdiavgift",
          "Yrkestransportlova",
          "Forbrukerloven"
        ],
        CorrectAnswer: "Forskrift om takstberegning og maksimalpriser for løyvepliktig drosjetransport med motorvogn",
        Category: "Regler om prisopplysning og makspriser"
      },
      {
        Question: "I henhold til Yrkestransportlova § 9, hva er kravet til loggføring av drosjeturer?",
        Answers: [
          "Turene skal loggføres korrekt og nøyaktig",
          "Turene skal kun loggføres ved turer over 50 km",
          "Turene skal loggføres kun på papir",
          "Turene trenger ikke å loggføres hvis de er betalt kontant"
        ],
        CorrectAnswer: "Turene skal loggføres korrekt og nøyaktig",
        Category: "Loggføring av drosjeturer"
      },
      {
        Question: "Hvordan skal drosjeturer loggføres ifølge Yrkestransportforskrift § 48 d)?",
        Answers: [
          "Gjennom sentralen",
          "Via GPS",
          "Manuelt på papir",
          "Gjennom en mobilapp"
        ],
        CorrectAnswer: "Gjennom sentralen",
        Category: "Loggføring av drosjeturer"
      },
      {
        Question: "Hvilke krav til kontroll av taksameter stilles?",
        Answers: [
          "Enten må kontroll være gjennomført eller så må bruker kunne dokumentere at kontroll er bestilt hos Justervesenet.",
          "Kontroll av taksameter må være gjennomført.",
          "Det stilles ingen krav til kontroll av taksameter.",
          "Kontroll må gjennomføres innen 18 måneder etter at løyvehaver har kjøpt ny bil."
        ],
        CorrectAnswer: "Enten må kontroll være gjennomført eller så må bruker kunne dokumentere at kontroll er bestilt hos Justervesenet.",
        Category: "Loggføring av drosjeturer"
      },
      {
        Question: "Skal kontrollrapport ligge i taxien?",
        Answers: [
          "Nei, denne blir liggende i Justervesenets database.",
          "Ja, denne skal løyvehaver alltid ha med seg i taxien."
        ],
        CorrectAnswer: "Nei, denne blir liggende i Justervesenets database.",
        Category: "Loggføring av drosjeturer"
      },
      {
        Question: "Hvor skal samsvarserklæringen ligge?",
        Answers: [
          "Samsvarserklæring skal alltid ligge i bilen.",
          "Den trenger ikke å ligge i bilen, men løyvehaver skal ha denne på sin hjemmeadresse.",
          "Den skal ligge hos sentralen løyvehaver kjører for.",
          "Man trenger ikke samsvarserklæring."
        ],
        CorrectAnswer: "Samsvarserklæring skal alltid ligge i bilen.",
        Category: "Loggføring av drosjeturer"
      },
      {
        Question: "Hvor lang tid i forveien vil løyvehaver få beskjed om at Justervesenet kommer på kontroll?",
        Answers: ["Ca 3 uker", "Ca 6 måneder", "Ca 8 uker", "Ca 3 måneder"],
        CorrectAnswer: "Ca 3 uker",
        Category: "Loggføring av drosjeturer"
      },
      {
        Question: "Hva er hovedformålet med Vegtrafikkloven?",
        Answers: [
          "Å fremme en sikker trafikkavvikling",
          "Å sikre fri flyt av trafikk",
          "Å maksimere inntektene fra bompenger",
          "Å redusere antall biler på veien"
        ],
        CorrectAnswer: "Å fremme en sikker trafikkavvikling",
        Category: "Vegtrafikkloven"
      },
      {
        Question: "Hvilken av følgende er IKKE en del av Vegtrafikkloven?",
        Answers: [
          "Regler om parkering på privat eiendom",
          "Regler om førerrett",
          "Regler om kjøretøyets tekniske tilstand",
          "Regler om trafikkopplæring"
        ],
        CorrectAnswer: "Regler om parkering på privat eiendom",
        Category: "Vegtrafikkloven"
      },
      {
        Question: "Hva er den generelle fartsgrensen i tettbygd strøk ifølge Vegtrafikkloven?",
        Answers: ["50 km/t", "60 km/t", "40 km/t", "30 km/t"],
        CorrectAnswer: "50 km/t",
        Category: "Vegtrafikkloven"
      },
      {
        Question: "Hvem har ansvaret for å sørge for at et kjøretøy er i teknisk god stand før det settes i trafikk?",
        Answers: [
          "Eieren av kjøretøyet",
          "Verkstedet som sist utførte service",
          "Forsikringsselskapet",
          "Passasjeren i kjøretøyet"
        ],
        CorrectAnswer: "Eieren av kjøretøyet",
        Category: "Vegtrafikkloven"
      },
      {
        Question: "Hvilke krav stilles blant annet til kjøretøyet?",
        Answers: [
          "Taklampe, registrert som drosje og taksameter.",
          "Firehjulsdrift, taklampe og taksameter.",
          "«Alternativ kontrollutrustning», taklampe og minimum 5 seter.",
          "Tilhengerfeste og minimum 5 seter."
        ],
        CorrectAnswer: "Taklampe, registrert som drosje og taksameter.",
        Category: "Vegtrafikkloven"
      },
      
      {
        Question: "Hva kreves blant annet (minimum) for å få løyve for drosjedrift?",
        Answers: [
          "Bankgaranti",
          "Garantisum fra LH",
          "Minimum 5 års erfaring som drosjesjåfør",
          "Eierskap til minimum 5 drosjebiler"
        ],
        CorrectAnswer: "Bankgaranti",
        Category: "Yrkestransportlov med tilhørende forskrifter"
      },
      {
        Question: "Hvem er ansvarlig for gjenglemte ting fra passasjer (hittegods) i en drosje?",
        Answers: [
          "Løyvehaver",
          "Sjåføren",
          "Kunden",
          "Sentralen"
        ],
        CorrectAnswer: "Løyvehaver",
        Category: "Yrkestransportlov med tilhørende forskrifter"
      },
      {
        Question: "Hva er riktig om loggføring av drosjeturer ifølge Yrkestransportlova?",
        Answers: [
          "Den skal være korrekt og nøyaktig",
          "Den skal kun inkludere turer over 100 kr",
          "Den skal kun inkludere turer på kveldstid",
          "Den skal kun inkludere turer med mer enn én passasjer"
        ],
        CorrectAnswer: "Den skal være korrekt og nøyaktig",
        Category: "Yrkestransportlov med tilhørende forskrifter"
      },
      {
        Question: "Hvor lenge skal man lagre bilag på papir ifølge Yrkestransportlova?",
        Answers: [
          "5 år",
          "2 år",
          "10 år",
          "1 år"
        ],
        CorrectAnswer: "5 år",
        Category: "Yrkestransportlov med tilhørende forskrifter"
      },
      {
        Question: "Hva er kravet for enerett på løyve i en kommune ifølge Yrkestransportlova?",
        Answers: [
          "Maksimalt 200 000 innbyggere og befolkningstetthet på 80 per km2",
          "Minst 500 000 innbyggere",
          "Ingen spesifikke krav",
          "Maksimalt 100 000 innbyggere uavhengig av befolkningstetthet"
        ],
        CorrectAnswer: "Maksimalt 200 000 innbyggere og befolkningstetthet på 80 per km2",
        Category: "Yrkestransportlov med tilhørende forskrifter"
      },
      {
        Question: "Hvilket dokument må en drosjesjåfør ha tilgjengelig i bilen hvis passasjeren spør ifølge Yrkestransportlova?",
        Answers: [
          "Priskalkuleringsskjema",
          "Kjøreseddel",
          "Løyvenummer",
          "Førerkort"
        ],
        CorrectAnswer: "Priskalkuleringsskjema",
        Category: "Yrkestransportlov med tilhørende forskrifter"
      },
      {
        Question: "Hva er kravet til arbeidstid og pause for en drosjesjåfør ifølge Yrkestransportlova?",
        Answers: [
          "5,5 timer kjøring og 1 pause (30 min)",
          "6 timer kjøring og 2 pauser (15 min hver)",
          "7 timer kjøring uten pause",
          "4,5 timer kjøring og 1 pause (45 min)"
        ],
        CorrectAnswer: "5,5 timer kjøring og 1 pause (30 min)",
        Category: "Yrkestransportlov med tilhørende forskrifter"
      },
      {
        Question: "Hvordan betales trygdeforpliktelser for ansatte i en drosjevirksomhet ifølge Yrkestransportlova?",
        Answers: [
          "Via arbeidsgiveravgift",
          "Direkte av ansatt",
          "Gjennom skattetrekk",
          "Via sentralen"
        ],
        CorrectAnswer: "Via arbeidsgiveravgift",
        Category: "Yrkestransportlov med tilhørende forskrifter"
      },      
      {
        Question: "Hva er forskjellen mellom resultatregnskap og likviditetsregnskap?",
        Answers: [
          "Resultatregnskap viser inntekter og kostnader, mens likviditetsregnskap viser innbetalinger og utbetalinger",
          "Resultatregnskap viser kun inntekter, mens likviditetsregnskap viser kun utgifter",
          "Resultatregnskap er for store selskaper, mens likviditetsregnskap er for små selskaper",
          "Resultatregnskap er basert på estimater, mens likviditetsregnskap er basert på faktiske tall"
        ],
        CorrectAnswer: "Resultatregnskap viser inntekter og kostnader, mens likviditetsregnskap viser innbetalinger og utbetalinger",
        Category: "Økonomiforståelse"
      },
      {
        Question: "Hva er den største kostnaden i en drosjevirksomhet?",
        Answers: [
          "Faste utgifter",
          "Investering i bil",
          "Drivstoff",
          "Lønn til sjåfører"
        ],
        CorrectAnswer: "Faste utgifter",
        Category: "Økonomiforståelse"
      },
      {
        Question: "Hvordan beregnes MVA-termin?",
        Answers: [
          "Utgående moms minus inngående MVA",
          "Total omsetning multiplisert med momssatsen",
          "Inngående moms minus utgående moms",
          "Totalt salg delt på antall dager i momsterminen"
        ],
        CorrectAnswer: "Utgående moms minus inngående MVA",
        Category: "Økonomiforståelse"
      },
      {
        Question: "Hva lager man et likviditetsbudsjett av?",
        Answers: [
          "Innbetaling minus utbetaling",
          "Total omsetning minus faste kostnader",
          "Lønn minus skatt",
          "Totalt salg minus returer"
        ],
        CorrectAnswer: "Innbetaling minus utbetaling",
        Category: "Økonomiforståelse"
      },
      {
        Question: "Du har en omsetning forrige måned på 318 000 kr inkl MVA. MVA-satsen er 6%. Hva er MVA for denne måneden?",
        Answers: [
          "18 000 kr",
          "19 080 kr",
          "31 800 kr",
          "6 360 kr"
        ],
        CorrectAnswer: "18 000 kr",
        Category: "Økonomiforståelse"
      },
      {
        Question: "Hva er hovedformålet med budsjettering i en drosjevirksomhet?",
        Answers: [
          "Å planlegge og kontrollere økonomien",
          "Å rapportere til skattemyndighetene",
          "Å bestemme lønnsnivået for sjåførene",
          "Å tiltrekke seg investorer"
        ],
        CorrectAnswer: "Å planlegge og kontrollere økonomien",
        Category: "Økonomiforståelse"
      },
      {
        Question: "Hva er en investering i en drosjevirksomhet?",
        Answers: [
          "Kjøp av varige driftsmidler som drosjebiler eller teknologi for å generere fremtidig inntekt",
          "Kjøp av drivstoff for drosjene",
          "Betaling av lønn til sjåførene",
          "Betaling av månedlig leie for kontorlokaler"
        ],
        CorrectAnswer: "Kjøp av varige driftsmidler som drosjebiler eller teknologi for å generere fremtidig inntekt",
        Category: "Økonomiforståelse"
      },
      {
        Question: "Hva er forskjellen mellom finansiering gjennom egenkapital og gjeld?",
        Answers: [
          "Egenkapital er finansiering gjennom eierens midler eller opptjent overskudd, mens gjeld er finansiering gjennom lån",
          "Egenkapital er finansiering gjennom banklån, mens gjeld er finansiering gjennom eierens midler",
          "Egenkapital og gjeld er det samme",
          "Egenkapital er kun for store selskaper, mens gjeld er for små selskaper"
        ],
        CorrectAnswer: "Egenkapital er finansiering gjennom eierens midler eller opptjent overskudd, mens gjeld er finansiering gjennom lån",
        Category: "Økonomiforståelse"
      },
      {
        Question: "Hva er hovedformålet med regnskap i en drosjevirksomhet?",
        Answers: [
          "Å gi en systematisk oversikt over virksomhetens økonomiske transaksjoner",
          "Å beregne skatt",
          "Å tiltrekke seg kunder",
          "Å bestemme prisen på drosjeturene"
        ],
        CorrectAnswer: "Å gi en systematisk oversikt over virksomhetens økonomiske transaksjoner",
        Category: "Økonomiforståelse"
      },
      {
        Question: "Hva er inntekt i en drosjevirksomhet?",
        Answers: [
          "Penger mottatt fra kunder for utførte tjenester",
          "Penger spart ved å redusere kostnader",
          "Penger mottatt fra salg av drosjebiler",
          "Penger mottatt fra banklån"
        ],
        CorrectAnswer: "Penger mottatt fra kunder for utførte tjenester",
        Category: "Økonomiforståelse"
      },
      {
        Question: "Hva er kostnader i en drosjevirksomhet?",
        Answers: [
          "Utgifter knyttet til drift av virksomheten, som lønn, drivstoff, vedlikehold, osv.",
          "Kun utgifter knyttet til kjøp av drosjebiler",
          "Kun skatter og avgifter",
          "Kun lønn til sjåførene"
        ],
        CorrectAnswer: "Utgifter knyttet til drift av virksomheten, som lønn, drivstoff, vedlikehold, osv.",
        Category: "Økonomiforståelse"
      },
      {
        Question: "Hva er forskjellen mellom direkte og indirekte kostnader i en drosjevirksomhet?",
        Answers: [
          "Direkte kostnader kan spores direkte til en bestemt tur eller tjeneste, mens indirekte kostnader er generelle driftskostnader",
          "Direkte kostnader er kun lønn, mens indirekte kostnader er alle andre utgifter",
          "Direkte kostnader er faste, mens indirekte kostnader er variable",
          "Direkte kostnader betales direkte til leverandører, mens indirekte kostnader betales via mellommenn"
        ],
        CorrectAnswer: "Direkte kostnader kan spores direkte til en bestemt tur eller tjeneste, mens indirekte kostnader er generelle driftskostnader",
        Category: "Økonomiforståelse"
      },
      {
        Question: "Hva er hovedformålet med kalkulering i en drosjevirksomhet?",
        Answers: [
          "Å bestemme prisen på drosjetjenester basert på kostnader og ønsket fortjeneste",
          "Å beregne skatt",
          "Å bestemme lønnsnivået for sjåførene",
          "Å rapportere til skattemyndighetene"
        ],
        CorrectAnswer: "Å bestemme prisen på drosjetjenester basert på kostnader og ønsket fortjeneste",
        Category: "Økonomiforståelse"
      },
      {
        Question: "Hva er en balanse i regnskapet?",
        Answers: [
          "En oversikt over virksomhetens eiendeler, gjeld og egenkapital på et bestemt tidspunkt",
          "En oversikt over virksomhetens inntekter og utgifter over en periode",
          "En oversikt over virksomhetens kunder og leverandører",
          "En oversikt over virksomhetens ansatte og deres lønn"
        ],
        CorrectAnswer: "En oversikt over virksomhetens eiendeler, gjeld og egenkapital på et bestemt tidspunkt",
        Category: "Økonomiforståelse"
      },
      {
        Question: "Hva er et bokføringsbilag og hvor lenge skal det oppbevares?",
        Answers: [
          "Dokumentasjon som brukes som grunnlag i regnskapet ditt. Det skal som hovedregel oppbevares i 5 år.",
          "Dokumentasjon som brukes som grunnlag i regnskapet ditt. Det skal som hovedregel oppbevares i 10 år.",
          "Dokumentasjon som brukes til å få drosjeløyve. Det skal oppbevares så lenge løyvet er aktivt.",
          "Et dokument som viser inntekter og utgifter. Det skal oppbevares i 1 år."
        ],
        CorrectAnswer: "Dokumentasjon som brukes som grunnlag i regnskapet ditt. Det skal som hovedregel oppbevares i 5 år.",
        Category: "Økonomiforståelse"
      },
      {
        Question: "Hva betyr forskuddstrekk?",
        Answers: [
          "Det er skatten som trekkes på lønningene til den ansatte.",
          "Det er skatt som kommer i tillegg til vanlig inntektsskatt.",
          "Det er det samme som selskapsskatt.",
          "Det er forskudd på MVA."
        ],
        CorrectAnswer: "Det er skatten som trekkes på lønningene til den ansatte.",
        Category: "Økonomiforståelse"
      },
      {
        Question: "Hva betyr forskuddsskatt og hvor ofte betales den?",
        Answers: [
          "Det er en måte å fordele skatten utover året. Den betales 4 ganger i året.",
          "Det er skatt for forrige års inntekt og betales på slutten av hvert år.",
          "Det er MVA for neste års inntekter.",
          "Det er det samme som forskuddsskatt."
        ],
        CorrectAnswer: "Det er en måte å fordele skatten utover året. Den betales 4 ganger i året.",
        Category: "Økonomiforståelse"
      },
      {
        Question: "Hva er forskjellen på direkte skatt og indirekte skatt?",
        Answers: [
          "Direkte skatt omfatter blant annet inntektskatt, arbeidsgiveravgift, formueskatt og eiendomsskatt. Indirekte skatt er MVA.",
          "Direkte skatt er MVA. Indirekte skatt omfatter blant annet inntektskatt, arbeidsgiveravgift, formueskatt og eiendomsskatt.",
          "Direkte skatt betales hver mnd. Indirekte skatt betales årlig.",
          "Direkte skatt gjelder personer og ENK. Indirekte skatt gjelder aksjeselskaper (AS)."
        ],
        CorrectAnswer: "Direkte skatt omfatter blant annet inntektskatt, arbeidsgiveravgift, formueskatt og eiendomsskatt. Indirekte skatt er MVA.",
        Category: "Økonomiforståelse"
      },
      {
        Question: "Hva er merverdiavgift (MVA)?",
        Answers: [
          "En skatt på forbruk av varer og tjenester",
          "En skatt på inntekt",
          "En skatt på eiendom",
          "En skatt på import"
        ],
        CorrectAnswer: "En skatt på forbruk av varer og tjenester",
        Category: "Avgifter"
      },
      {
        Question: "Hvordan beregnes arbeidsgiveravgift?",
        Answers: [
          "En prosentandel av lønnen som betales til ansatte",
          "En fast sum per ansatt",
          "En prosentandel av virksomhetens overskudd",
          "En prosentandel av virksomhetens omsetning"
        ],
        CorrectAnswer: "En prosentandel av lønnen som betales til ansatte",
        Category: "Avgifter"
      },
      {
        Question: "Hva er formålet med myndighetenes innkrevingsforpliktelse?",
        Answers: [
          "Å sikre at skatter og avgifter blir betalt til rett tid",
          "Å overvåke virksomhetens økonomi",
          "Å bestemme prisen på varer og tjenester",
          "Å gi rabatter til visse grupper av befolkningen"
        ],
        CorrectAnswer: "Å sikre at skatter og avgifter blir betalt til rett tid",
        Category: "Avgifter"
      },
      {
        Question: "Hvordan kan avgifter brukes som klimatiltak?",
        Answers: [
          "Ved å sette høyere avgifter på varer og tjenester som har høy miljøpåvirkning for å redusere forbruket",
          "Ved å gi avgiftslettelser til store selskaper",
          "Ved å sette lavere avgifter på alle varer og tjenester",
          "Ved å øke avgiftene på grønne produkter"
        ],
        CorrectAnswer: "Ved å sette høyere avgifter på varer og tjenester som har høy miljøpåvirkning for å redusere forbruket",
        Category: "Avgifter"
      },
      {
        Question: "Hva er formålet med differensiert arbeidsgiveravgift?",
        Answers: [
          "Å stimulere til økt sysselsetting i distriktene ved å gi lavere avgiftssatser",
          "Å øke inntektene til staten",
          "Å gi høyere lønn til ansatte i byene",
          "Å redusere antall småbedrifter"
        ],
        CorrectAnswer: "Å stimulere til økt sysselsetting i distriktene ved å gi lavere avgiftssatser",
        Category: "Avgifter"
      },
      {
        Question: "Hva er en fritakssats i forbindelse med MVA?",
        Answers: [
          "En sats der det ikke beregnes MVA, ofte fordi varen eller tjenesten er unntatt fra MVA",
          "En sats som gir rabatt på MVA",
          "En sats som kun gjelder for store selskaper",
          "En sats som kun gjelder for eksporterte varer"
        ],
        CorrectAnswer: "En sats der det ikke beregnes MVA, ofte fordi varen eller tjenesten er unntatt fra MVA",
        Category: "Avgifter"
      },
      {
        Question: "Hva er forskjellen på inngående og utgående MVA?",
        Answers: [
          "Inngående MVA er betalt MVA og utgående MVA er MVA du får ved salg av varer og tjenester",
          "Inngående MVA er MVA du får ved salg av varer og tjenester, utgående MVA er betalt MVA",
          "Det er det samme",
          "Inngående MVA gjelder første halvår, utgående MVA gjelder andre halvår"
        ],
        CorrectAnswer: "Inngående MVA er betalt MVA og utgående MVA er MVA du får ved salg av varer og tjenester",
        Category: "Avgifter"
      },

      {
        Question: "Hva er forskuddstrekk?",
        Answers: [
          "En del av arbeidstakerens lønn som arbeidsgiver trekker og betaler direkte til skattemyndighetene",
          "En skatt som betales ved årets slutt",
          "En skatt som betales ved kjøp av varer og tjenester",
          "En skatt som betales ved import av varer"
        ],
        CorrectAnswer: "En del av arbeidstakerens lønn som arbeidsgiver trekker og betaler direkte til skattemyndighetene",
        Category: "Skatter"
      },
      {
        Question: "Hva er forskuddsskatt?",
        Answers: [
          "En skatt som selvstendig næringsdrivende og andre betaler basert på forventet inntekt for året",
          "En skatt som betales ved årets slutt",
          "En skatt som betales ved kjøp av varer og tjenester",
          "En skatt som betales ved import av varer"
        ],
        CorrectAnswer: "En skatt som selvstendig næringsdrivende og andre betaler basert på forventet inntekt for året",
        Category: "Skatter"
      },
      {
        Question: "Hva er etterskuddsskatt?",
        Answers: [
          "Skatt som betales etter at inntekten er mottatt, ofte basert på det faktiske resultatet for året",
          "Skatt som betales på forhånd",
          "Skatt som betales ved kjøp av varer og tjenester",
          "Skatt som betales ved import av varer"
        ],
        CorrectAnswer: "Skatt som betales etter at inntekten er mottatt, ofte basert på det faktiske resultatet for året",
        Category: "Skatter"
      },
      {
        Question: "Hvordan kan skatter brukes som et verktøy for økonomisk politikk?",
        Answers: [
          "Ved å justere skattesatser for å stimulere eller dempe økonomisk aktivitet",
          "Ved å sette faste skattesatser uavhengig av økonomiske forhold",
          "Ved å øke skattene på grønne produkter",
          "Ved å gi skattelettelser kun til store selskaper"
        ],
        CorrectAnswer: "Ved å justere skattesatser for å stimulere eller dempe økonomisk aktivitet",
        Category: "Skatter"
      },
      {
        Question: "Hva er formålet med skatteplanlegging?",
        Answers: [
          "Å optimalisere skattebetalinger innenfor lovens rammer for å minimere skattebyrden",
          "Å unngå å betale skatt",
          "Å øke inntektene til staten",
          "Å gi høyere lønn til ansatte"
        ],
        CorrectAnswer: "Å optimalisere skattebetalinger innenfor lovens rammer for å minimere skattebyrden",
        Category: "Skatter"
      },
      {
        Question: "Hvorfor er det viktig for en drosjevirksomhet å ha ansvarsforsikring?",
        Answers: [
          "For å dekke erstatningsansvar ved skade på personer eller ting",
          "For å dekke kostnader ved sykdom blant sjåførene",
          "For å dekke kostnader ved tap av løyve",
          "For å dekke kostnader ved tap av kunder"
        ],
        CorrectAnswer: "For å dekke erstatningsansvar ved skade på personer eller ting",
        Category: "Forsikringer"
      },
      {
        Question: "Hva dekker kaskoforsikring for en drosje?",
        Answers: [
          "Skade på egen bil, uavhengig av hvem som er skyld i skaden",
          "Kun skader forårsaket av kunden",
          "Kun skader forårsaket av andre biler",
          "Kun skader forårsaket av naturkatastrofer"
        ],
        CorrectAnswer: "Skade på egen bil, uavhengig av hvem som er skyld i skaden",
        Category: "Forsikringer"
      },
      {
        Question: "Hva er formålet med yrkesskadeforsikring for drosjesjåfører?",
        Answers: [
          "Å gi erstatning ved yrkesskade eller yrkessykdom",
          "Å dekke tap av inntekt ved streik",
          "Å dekke kostnader ved tap av løyve",
          "Å gi erstatning ved trafikkulykker"
        ],
        CorrectAnswer: "Å gi erstatning ved yrkesskade eller yrkessykdom",
        Category: "Forsikringer"
      },
      {
        Question: "Hvorfor er det viktig for en drosjevirksomhet å ha avbruddsforsikring?",
        Answers: [
          "For å dekke tap av inntekt ved uforutsette hendelser som forhindrer normal drift",
          "For å dekke kostnader ved skader på bilen",
          "For å dekke tap av kunder",
          "For å dekke kostnader ved sykdom blant sjåførene"
        ],
        CorrectAnswer: "For å dekke tap av inntekt ved uforutsette hendelser som forhindrer normal drift",
        Category: "Forsikringer"
      }
]
export default Questions;