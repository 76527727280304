import React, { useState, useEffect } from 'react';
import { ToggleButton, ButtonGroup, Form, Button } from 'react-bootstrap';
import Questions from './questions';
import './styles.css';
import RidelLogo_hoved_vektor from './../assets/RidelLogo_hoved_vektor.svg';

const Quiz = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  useEffect(() => {
    if (Questions) {
      setQuestions(shuffleArray(Questions));
    }
  }, []);

  const shuffleArray = (array) => {
    if (!array) return [];
    return array.map((question) => {
      const shuffledAnswers = question.Answers.map((answer) => ({
        text: answer,
        backgroundColor: null,
      })).sort(() => Math.random() - 0.5);

      return {
        ...question,
        Answers: shuffledAnswers,
      };
    });
  };

  const handleAnswerClick = (selectedAnswer) => {
    const isCorrect = selectedAnswer === questions[currentQuestion]?.CorrectAnswer;

    setQuestions((prevQuestions) =>
      prevQuestions.map((question, index) => {
        if (index === currentQuestion) {
          return {
            ...question,
            Answers: question.Answers.map((answer) => ({
              ...answer,
              backgroundColor:
                answer.text === questions[currentQuestion]?.CorrectAnswer
                  ? "#48bb88" // Grønn (suksess) for riktig svar
                  : answer.text === selectedAnswer
                  ? "#dc3545" // Rød (fare) for feil svar
                  : answer.backgroundColor || "#6c757d", // Behold eksisterende bakgrunnsfarge for andre svar
            })),
          };
        }
        return question;
      })
    );

    if (isCorrect) {
      setScore((prevScore) => prevScore + 1);
    }

    setSelectedAnswer(selectedAnswer);
  };

  const nextQuestion = () => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
      setSelectedAnswer(null);
    } else {
      setShowScore(true);
    }
  };

  const restartQuiz = () => {
    setQuestions(shuffleArray(Questions));
    setCurrentQuestion(0);
    setScore(0);
    setShowScore(false);
    setSelectedAnswer(null);
  };

  return (
    <div className='Quiz'>
      <img src={RidelLogo_hoved_vektor} alt='logo' className='logo-svg' />
      {showScore ? (
        <div>
          <p>Du fikk {score} av {questions.length} riktig!</p>
          <Button onClick={restartQuiz}>Start på nytt</Button>
        </div>
      ) : (
        <div>
          <h2>Spørsmål {currentQuestion + 1}</h2>
          <p>{questions[currentQuestion]?.Question}</p>
          <Form>
            <ButtonGroup className='btn-group' vertical>
              {questions[currentQuestion]?.Answers.map((answer, index) => (
                <div key={index} className="mb-2">
                  <ToggleButton
                    id={`toggle-check-${index}`}
                    type="checkbox"
                    style={{
                      borderColor: "#474b59",
                      width: "100%",
                      backgroundColor: answer.backgroundColor || "#6c757d",
                    }}
                    checked={selectedAnswer === answer.text}
                    value={answer.text}
                    onChange={(e) => handleAnswerClick(e.currentTarget.value)}
                    disabled={selectedAnswer !== null}
                    className='toggleButton'
                  >
                    {answer.text}
                  </ToggleButton>
                </div>
              ))}
            </ButtonGroup>
            <div></div>
            <Button className="submit" onClick={nextQuestion}>
              Neste spørsmål
            </Button>
          </Form>
        </div>
      )}
    </div>
  );
};

export default Quiz;
