 import { Component } from "react";
import Quiz from "./components/Quiz";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
class App extends Component {

 
    render() {
        
        return (
            <div>
              <Quiz></Quiz>
            </div>
        );
    }
}
 
export default App;